
// import Vue from 'vue'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

import { ClientsTable } from '@/typings/ClientsTypings'

import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'

@Component({
  name: 'ClientsList'
})

export default class ClientsList extends Mixins(TableMixin) {
  @Prop({ default: [] }) readonly clientsList!: ClientsTable
  @Prop({ default: false }) readonly simpleTable!: boolean

  countOrders(number: number): string {
    return stringUtils.pluralForm(number, ['заявка', 'заявки', 'заявок'])
  }
}
