
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'

import { mapGetters } from 'vuex'
import { UserRoles } from '@/typings/UserTypings'

import HomeLastNotifications from '@/views/Home/HomeLastNotifications.vue'
import ClientsList from '@/views/Clients/ClientsList.vue'

@Component({
  name: 'Home',
  components: {
    HomeLastNotifications,
    ClientsList
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})

export default class Home extends Vue {
  isModalFormValid = false
  lastNotifications = [
    {
      id: 1,
      title: 'Создана новая заявка на лизинг PEUGEOT Traveller компания ООО “Делили апельсин” ИНН 777777777777',
      date: '22.02.2012'
    },
    {
      id: 2,
      title: 'Создана новая заявка на лизинг PEUGEOT Traveller компания ООО “Делили апельсин” ИНН 777777777777',
      date: '22.02.2012'
    }
  ]

  clientsList = {
    headers: [
      {
        text: 'Наименование клиента',
        value: 'name'
      },
      {
        text: 'Тип клиента',
        value: 'typeClient'
      },
      {
        text: 'ИНН клиента',
        value: 'inn'
      },
      {
        text: 'Количество заявок',
        value: 'allOrdersCount'
      },
      {
        text: 'Заявки на рассмотрении',
        value: 'pendingOrdersCount'
      },
      {
        text: '',
        value: 'hasNotifications'
      }
    ],
    items: [
      {
        id: 1,
        name: 'ООО Оранжевое яблоко',
        typeClient: {
          id: 1,
          name: 'Юр. лицо'
        },
        inn: '88123771283',
        allOrdersCount: 15,
        pendingOrdersCount: 12,
        hasNotifications: true
      },
      {
        id: 2,
        name: 'ООО Кривые зеркала',
        typeClient: {
          id: 2,
          name: 'Физ. лицо'
        },
        inn: '88432772348',
        allOrdersCount: 3,
        pendingOrdersCount: 1,
        hasNotifications: false
      }
    ]
  }

  // get userRole() {
  //   return UserRoles[this.user.user_role_id]
  // }

  created() {
    // @ts-ignore
    this.$setLoading(true)
    // @ts-ignore
    const userRole = UserRoles[this.user.user_role_id]
    switch (userRole) {
      case 'manager-lc':
        this.$router.push({ name: 'SearchLeasObjectsNewRequest' })
        break
      case 'manager-ds':
        this.$router.push({ name: 'RequestLeasObjectsList' })
        break
      case 'superviser':
        this.$router.push({ name: 'SuperviserRequests' })
        break
      case 'admin':
        this.$router.push({ name: 'CarSearchRequests' })
    }
  }
}
