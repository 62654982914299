
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { LastNotifications } from '@/typings/HomeTypings'

@Component({
  name: 'HomeLastNotifications'
})

export default class HomeLastNotifications extends Vue {
  @Prop({ default: [] }) readonly lastNotifications!: LastNotifications[]
}
